const CallToActionPurple = () => {
  return (
    <svg viewBox="0 0 1920 320" fill="none">
      <rect
        x="-1440"
        y="-320"
        width="4320"
        height="960"
        fill="url(#grid-pattern-cta)"
      />
      <g transform="translate(-1, -1)">
        <path
          opacity="0.25"
          d="M560.5 34.9998L525.5 -0.000163277L240.5 4.06893e-05L120.5 -116L120.5 3.78279e-05L201 80L525.5 79.9998L560.5 43.9998L560.5 34.9998Z"
          fill="var(--brand-color-purple-5)"
        />
        <path
          d="M-0.5 80.0007L201 80.0003L685.5 79.9993L720.5 43.9993L720.5 34.9993L685.5 -0.000704964L-4.99999 -0.000704294"
          stroke="var(--brand-color-purple-5)"
          strokeWidth="1.5"
          strokeDasharray="3 3"
        />
        <path
          d="M400.5 -0.000709534L605 -0.000709534L640 35.9993V44.9993L605 79.9993L440.5 79.9993L320 200.499L-6.5 200.499L-6.5 120.499L280 120.499L400.5 -0.000709534Z"
          stroke="var(--brand-color-purple-5)"
          strokeWidth="1.5"
        />
        <circle
          opacity="0.75"
          cx="481"
          cy="40.0001"
          r="20"
          transform="rotate(90 481 40.0001)"
          fill="var(--brand-color-purple-5)"
        />
        <path
          d="M1561 159.5L1646 159.5L1681 124.5L1681 114.5L1646 79.5001L1521 79.5001L1441 159.5L1441 332L1476.5 367L1485.5 367L1521 332L1521 199.5L1561 159.5Z"
          stroke="var(--brand-color-purple-5)"
          strokeWidth="1.5"
        />
        <path
          d="M1681.5 79.5001H1636L1601 114.5V124.5L1636 159.5H1721.5L1801.5 79.5001V-92.9999L1766 -128L1757 -128L1721.5 -92.9999V39.5L1681.5 79.5001Z"
          stroke="var(--brand-color-purple-5)"
          strokeWidth="1.5"
          strokeDasharray="3 3"
        />
        <circle
          opacity="0.75"
          cx="1521"
          cy="140"
          r="20"
          transform="rotate(90 1521 140)"
          fill="var(--brand-color-purple-5)"
        />
        <path
          opacity="0.25"
          d="M1486 599.5L1521 564L1521 235L1485 200L1476 200L1441 235L1441 564L1477 599.5L1486 599.5Z"
          fill="var(--brand-color-purple-5)"
          stroke="var(--brand-color-purple-5)"
        />
      </g>
      <defs>
        <pattern
          id="grid-pattern-cta"
          viewBox="0 0 40 40"
          width="0.925925926%"
          height="4.16666667%"
        >
          <rect width="40" height="40" fill="var(--brand-color-purple-7)" />
          <line
            x1="39"
            y1="0"
            x2="39"
            y2="39"
            stroke="var(--brand-color-purple-6)"
            strokeDasharray="2 2"
            strokeOpacity="0.5"
          />
          <line
            x1="0"
            y1="39"
            x2="39"
            y2="39"
            stroke="var(--brand-color-purple-6)"
            strokeDasharray="2 2"
            strokeOpacity="0.5"
          />
        </pattern>
      </defs>
    </svg>
  );
};

export default CallToActionPurple;
