import { useEffect } from 'react';
import gsap from 'gsap';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';

gsap.registerPlugin(MotionPathPlugin);

export default function HomepageAnimated() {
  useEffect(() => {
    const circles = document.querySelectorAll('circle[dataindex]');
    const paths = document.querySelectorAll('path[dataindex]');

    const tl = gsap.timeline({ repeat: -1 });

    circles.forEach((circle, index) => {
      const path = paths[index];

      tl.to(circle, {
        duration: 0.2,
        opacity: 0.75,
        ease: 'linear',
      });

      tl.to(circle, {
        duration: 3,
        ease: 'none',
        motionPath: {
          path: path,
          align: path,
          alignOrigin: [0.5, 0.5],
          autoRotate: false,
        },
      });
    });
  }, []);

  return (
    <svg
      width="482"
      height="1024"
      viewBox="0 0 482 1024"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2841_5246)">
        <path
          d="M0.999996 354.5L0.999998 961L55 1013.5L68.5 1013.5L121 961L121 414.5L281 255.5L281 -51.25L227 -104.5L213.5 -104.5L161 -51.25L161 195.5L0.999996 354.5Z"
          stroke="var(--brand-color-blue-6)"
          strokeWidth="1.5"
          strokeDasharray="3 3"
          style={{
            transform: 'translateX(199.5px)',
          }}
        />

        <path
          opacity="0.25"
          d="M253 -246L200.5 -192.64L200.5 429.392L254.5 482L266.5 482L321.5 429.392L321.5 -192.64L266.5 -246L253 -246Z"
          fill="var(--brand-color-purple-6)"
        />
        <circle
          dataindex={0}
          opacity="0"
          cx="-200"
          cy="-200"
          r="30"
          transform="rotate(-180 260.5 354.998)"
          fill="var(--brand-color-purple-5)"
        />
        <circle
          dataindex={1}
          opacity="0"
          cx="-200"
          cy="-200"
          r="30"
          transform="rotate(-180 260.5 354.998)"
          fill="var(--brand-color-purple-5)"
        />
        <circle
          dataindex={2}
          opacity="0"
          cx="-200"
          cy="-200"
          r="30"
          transform="rotate(-180 260.5 354.998)"
          fill="var(--brand-color-purple-5)"
        />
        <path
          d="M320.5 154.5L320.5 509.5L266.5 562L254.5 562L200.5 509.5L200.5 214.5L1.00002 15L1.00004 -291.75L55 -345L68.5 -345L121 -291.75L121 -45L320.5 154.5Z"
          stroke="var(--brand-color-salmon-5)"
          strokeWidth="1.5"
        />
        <path dataindex={0} d="M7.5 -70.5L259.5 183V1024" stroke="none" />
        <path
          dataindex={1}
          d="M422.5 -50L422.5 224L259.5 384.5L259.5 1024"
          stroke="none"
        />
        <path dataindex={2} d="M259.5,-50 L259.5,1024" stroke="none" />
      </g>
      <defs>
        <clipPath id="clip0_2841_5246">
          <rect width="482" height="1024" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
